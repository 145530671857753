import styled from "styled-components";

export const MainDescriptionContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7rem 5%;
  gap: 5%;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
    padding: 3rem 5%;
    gap: 3rem;
  }
`;

export const DescriptionContainer = styled.div`
  flex-basis: 60%;
`;

export const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

export const CheckboxImage = styled.img`
  width: 2rem;
  height: 2rem;
`;
