import styled, { css } from "styled-components";

export const OfferSection = styled.section`
  display: flex;
  flex-direction: row;
  background-color: #053d2a;
  padding: 7rem;
  justify-content: space-between;

  @media (max-width: 1150px) {
    padding: 3rem;
    flex-direction: column;
    align-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
`;

export const OfferRow = styled.div<{
  numberOfElement: number;
  isInView: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding-left: ${({ numberOfElement }) => numberOfElement + 1}rem;
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;

  ${({ isInView }) =>
    isInView &&
    css`
      opacity: 100;
      transform: translateX(0);
    `}
`;

export const ArrowsContainer = styled.div``;

export const ArrowImage = styled.img`
  width: 1rem;
  height: 1rem;
  @media (max-width: 768px) {
    transform: rotate(180deg);
  }
`;

export const LogoContainer = styled.div`
  max-width: 45%;
`;

export const Image = styled.img`
  aspect-ratio: 2 / 1;
`;
