import React, { useState } from "react";
import { styled } from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 0.6;
`;

const Photo: React.FC<{ URL: string; thumbnail: string }> = ({
  URL,
  thumbnail,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Image
      // loading="lazy"
      onLoad={() => setIsLoaded(true)}
      alt="Gallery Photo"
      src={`${isLoaded ? URL : thumbnail}.webp`}
    />
  );
};

export default Photo;
