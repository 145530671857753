import styled, { css } from "styled-components";

export const TilesSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1%;
  row-gap: 4rem;
  padding: 0 0 9rem 0;
  font-size: 1.5rem;
  justify-content: space-between;

  @media (max-width: 1500px) {
    padding: 0 0 11rem 0;
    row-gap: 6rem;
  }

  @media (max-width: 1310px) {
    padding: 0 0 10rem 0;
    row-gap: 9rem;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 8rem;
    font-size: 1.1rem;
  }

  @media (max-width: 766px) {
    gap: 4rem;
    padding: 0 0 11rem 0 !important;
  }

  @media (max-width: 551px) {
    padding: 0 0 22rem 0 !important;
  }

  @media (max-width: 499px) {
    padding: 0 0 25rem 0 !important;
  }

  @media (max-width: 473px) {
    padding: 0 0 30rem 0 !important;
  }

  @media (max-width: 437px) {
    padding: 0 0 35rem 0 !important;
  }

  @media (max-width: 400px) {
    gap: 5rem;
    padding: 0 0 45rem 0 !important;
  }

  @media (max-width: 361px) {
    padding: 0 0 47rem 0 !important;
  }
`;

export const ImageContainer = styled.div`
  flex-basis: 49.5%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LongImageContainer = styled(ImageContainer)`
  flex-basis: 100%;
`;

export const Image = styled.img`
  aspect-ratio: 1.2 / 1;
`;

export const LongImage = styled.img`
  aspect-ratio: 2 / 1;

  @media (max-width: 820px) {
    aspect-ratio: 1.2 / 1;
  }
`;

export const ImageTile = styled.div<{
  $isInView: boolean;
  $isLeftOne: boolean;
}>`
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  top: 60%;
  min-height: 55%;
  width: 80%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  gap: 1rem;
  color: #092e1b;
  opacity: 0;
  transform: ${({ $isLeftOne }) =>
    $isLeftOne ? "translateX(-100%)" : "translateX(100%)"};
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;

  ${({ $isInView }) =>
    $isInView &&
    css`
      transform: translateY(0);
      opacity: 100;
    `}

  @media (max-width: 1440px) {
    background: rgba(255, 255, 255, 0.25);
    color: #092e1b;
  }

  @media (max-width: 820px) {
    top: 5%;
    color: #092e1b;
    border-radius: 5px;
  }
`;

export const LongImageTextContainer = styled(ImageTile)`
  top: 50%;

  @media (max-width: 820px) {
    top: 5%;
    color: #092e1b;
    border-radius: 5px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WriteToUsButton = styled.a<{ $isVisible: boolean }>`
  background-color: #3ab64d;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  border: unset;
  width: fit-content;
  cursor: pointer;
  opacity: 0;
  transition: 0.9s;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 100;
    `}

  &&:hover {
    background-color: #092e1b;
    transition: 0.7s;
  }
`;

export const ContactButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
