import Typography from "../atoms/Typography";
import * as S from "./Offer.styles";
import OfferRow from "./OfferRow";

function Offer() {
  const width = window.innerWidth;

  const offers = [
    "Montaż automatycznych systemów nawadniania",
    "Serwis istniejących systemów",
    "Linie kroplujące",
    "Tworzenie projektów",
    "Budowa instalacji wód deszczowych oraz montaż zbiorników",
    "Odwodnienie terenów (drenaże)",
    "Zakładanie trawników",
    "Usługi koparką łańcuchową",
    "Pomoc w realizacji odwiertów studni",
  ];

  return (
    <S.OfferSection data-test-id="Offer_Section" id="o-nas">
      <S.Column data-test-id="Column">
        {offers.map((offerName, index) => (
          <OfferRow indexNumber={index} text={offerName} />
        ))}
      </S.Column>
      {width > 1150 ? (
        <S.LogoContainer>
          <S.Image
            src={`${process.env.PUBLIC_URL}/gallery_6.webp`}
            alt="Offer Photo"
          />
        </S.LogoContainer>
      ) : null}
    </S.OfferSection>
  );
}

export default Offer;
