import styled from "styled-components";

export const HeroSection = styled.section`
  width: 100vw;
  /* min-height: 50vh; */
  background-color: #092e1b;
  display: flex;
  flex-direction: column;
  padding: 2rem 5%;
  gap: 5rem;

  @media (max-width: 768px) {
    padding: 1rem 5%;
  }
`;

export const HeroMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LogoMenu = styled.div`
  display: flex;
  align-items: center;
  height: 10rem;
  width: 10rem;

  @media (max-width: 768px) {
    height: 7rem;
    width: 7rem;
  }
`;

export const LogoImage = styled.img`
  border-radius: 100%;
`;

export const InteractiveSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  color: #fff;
`;

export const Link = styled.a`
  transition: 0.7s;
  &&:hover {
    color: #bdefbf;
    transition: 0.7s;
  }
`;

export const MainInformationPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10%;

  @media (max-width: 820px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const HeroTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const HeroButton = styled.a`
  width: fit-content;
  height: fit-content;
  padding: 0.8rem 1.4rem;
  background-color: rgb(0, 147, 203);
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 100%;
    order: 1;
  }
`;

export const HeroImageContainer = styled.div`
  min-width: 40%;

  @media (max-width: 768px) {
    min-width: unset;
  }
`;

export const HeroImage = styled.img`
  border-radius: 0 8px 8px 80px;
  /* aspect-ratio: 2 / 1; */
`;
