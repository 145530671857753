import React from "react";
import styled from "styled-components";

interface TypographyProps {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span";
  typographySize?: string;
  typographyColor?: string;
  typographyPadding?: string;
  typographyMargin?: string;
  typographyWeight?: string;
  children: React.ReactNode;
  typographyAlign?: string;
  typographyLineHeight?: string;
}

const Typography: React.FC<TypographyProps> = ({
  type,
  typographySize = "inherit",
  typographyColor = "inherit",
  typographyPadding = "0",
  typographyMargin = "0",
  typographyWeight = "normal",
  typographyAlign = "left",
  children,
  typographyLineHeight,
}) => {
  const Text = styled[type]`
    padding: ${typographyPadding};
    font-size: ${typographySize};
    color: ${typographyColor};
    margin: ${typographyMargin};
    font-weight: ${typographyWeight};
    text-align: ${typographyAlign};
    ${typographyLineHeight ? `line-height: ${typographyLineHeight}` : ""}
  `;
  return <Text>{children}</Text>;
};

export default Typography;
