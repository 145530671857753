import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: 100%;
  }

  a {
    text-decoration: unset;
    color: inherit;
    cursor: pointer;
  }

  body, html {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: "Open Sans", system-ui;
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  };
  
  ::-webkit-scrollbar-thumb {
    background-color: #555557;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  };

  ::-webkit-scrollbar-track {
    inset: 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
