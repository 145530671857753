import React from "react";
import {
  OfferRow as OfferRowStyle,
  ArrowImage,
  ArrowsContainer,
} from "./Offer.styles";
import Typography from "../atoms/Typography";
import { useInView } from "react-intersection-observer";

interface OfferRowProp {
  indexNumber: number;
  text: string;
}

const OfferRow: React.FC<OfferRowProp> = ({ indexNumber, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <OfferRowStyle
      key={text}
      numberOfElement={indexNumber}
      ref={ref}
      isInView={inView}
    >
      <ArrowsContainer>
        <ArrowImage
          src={`${process.env.PUBLIC_URL}/chevron.svg`}
          alt="Arrow Icon"
        />
      </ArrowsContainer>
      <Typography
        type="p"
        typographyAlign="left"
        typographyLineHeight="2rem"
        typographyWeight="bold"
        typographyColor="#fff"
      >
        {text}
      </Typography>
    </OfferRowStyle>
  );
};

export default OfferRow;
