import * as S from "./MainDescription.styles";
import Typography from "../atoms/Typography";

function MainDescription() {
  const checkboxList = [
    "Pracujemy na produktach renomowanych firm",
    "Bezpłatna wycena oraz projekt",
    "Konkurencyjne ceny",
  ];

  return (
    <S.MainDescriptionContainer data-test-id="Main_Description_Container">
      <S.DescriptionContainer data-test-id="Description_Container">
        <Typography
          type="h3"
          typographySize="2.2rem"
          typographyLineHeight="4rem"
        >
          Systemy nawadniające, zaprojektowane tak, aby dostarczyć każdej
          roślinie{" "}
          <Typography
            type="span"
            typographyColor="#0093cb"
            typographySize="2.2rem"
          >
            <strong>odpowiednią dawkę wody!</strong>
          </Typography>
        </Typography>
      </S.DescriptionContainer>
      <S.CheckboxList data-test-id="Checkbox_List">
        {checkboxList.map((checkboxString) => (
          <S.CheckBoxRow
            key={checkboxString}
            data-test-id={`Checbkox_Row_${checkboxString}`}
          >
            <S.CheckboxImage
              src={`${process.env.PUBLIC_URL}/checkbox.svg`}
              alt="Checkbox"
            />
            <Typography
              type="h4"
              typographyColor="#000"
              typographySize="1.5rem"
            >
              {checkboxString}
            </Typography>
          </S.CheckBoxRow>
        ))}
      </S.CheckboxList>
    </S.MainDescriptionContainer>
  );
}

export default MainDescription;
