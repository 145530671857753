import * as S from "./Gallery.styles";
import Photo from "./Photo";

function Gallery() {
  const photos = [
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_1`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_2`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_3`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_4`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_5`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    {
      realSize: `${process.env.PUBLIC_URL}/gallery_6`,
      thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    },
    // {
    //   realSize: `${process.env.PUBLIC_URL}/mag172`,
    //   thumbnail: `${process.env.PUBLIC_URL}/water_2_Low`,
    // },
  ];
  return (
    <S.Gallery data-test-id="Gallery">
      {photos.map(({ realSize, thumbnail }) => {
        return (
          <S.PhotoContainer
            key={realSize}
            data-test-id="Photo_Container"
            id="realizacje"
          >
            <Photo URL={realSize} thumbnail={thumbnail} />
          </S.PhotoContainer>
        );
      })}
    </S.Gallery>
  );
}

export default Gallery;
