import styled from "styled-components";

export const Gallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 7rem 0;
  row-gap: 0.3rem;

  @media (max-width: 820px) {
    padding: 0;
    margin: 4rem 0;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const PhotoContainer = styled.div`
  aspect-ratio: 1 / 0.6;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 0 0.3rem;

  @media (max-width: 820px) {
    max-width: 100%;
    padding: 0;
  }
`;
