import Typography from "../atoms/Typography";
import * as S from "./Footer.styles";

function Footer() {
  const width = window.innerWidth;
  return (
    <S.Footer data-test-id="Footer" id="kontakt">
      <S.LogoContainer data-test-id="Logo_Container">
        <S.LogoFooter>
          <S.LogoImage
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="Aqua Shot Logo"
          />
        </S.LogoFooter>
      </S.LogoContainer>
      <S.ContactContainer data-test-id="Contanct_Container">
        <Typography
          type="h5"
          typographyColor="#8E9D8F"
          typographyWeight="bold"
          typographyMargin="0 0 1rem 0"
          typographyAlign={width < 768 ? "center" : "left"}
        >
          Kontakt
        </Typography>
        {/* <a>
          <Typography
            type="p"
            typographyColor="#8E9D8F"
            typographyMargin="0 0 0.3rem 0"
            typographyAlign={width < 768 ? "center" : "left"}
          >
            Facebook
          </Typography>
        </a> */}
        <Typography
          type="p"
          typographyColor="#8E9D8F"
          typographyMargin="0 0 0.3rem 0"
          typographyAlign={width < 768 ? "center" : "left"}
        >
          <Typography type="span" typographyWeight="bold">
            E-MAIL:{" "}
          </Typography>
          nawodnienia.aquashot@gmail.com
        </Typography>
        <Typography
          type="p"
          typographyColor="#8E9D8F"
          typographyMargin="0 0 0.3rem 0"
          typographyAlign={width < 768 ? "center" : "left"}
        >
          <Typography type="span" typographyWeight="bold">
            TELEFON:{" "}
          </Typography>
          +48 721 491 336
        </Typography>
      </S.ContactContainer>
      <S.FastLinks>
        <Typography
          type="h5"
          typographyColor="#8E9D8F"
          typographyWeight="bold"
          typographyMargin="0 0 1rem 0"
          typographyAlign={width < 768 ? "center" : "left"}
        >
          Szybkie linki
        </Typography>
        <a href="#hero">
          <Typography
            type="p"
            typographyColor="#8E9D8F"
            typographyMargin="0 0 0.3rem 0"
            typographyAlign={width < 768 ? "center" : "left"}
          >
            O Nas
          </Typography>
        </a>

        <a href="#dlaczego-warto">
          <Typography
            type="p"
            typographyColor="#8E9D8F"
            typographyMargin="0 0 0.3rem 0"
            typographyAlign={width < 768 ? "center" : "left"}
          >
            Dlaczego Warto
          </Typography>
        </a>
        <a href="#o-nas">
          <Typography
            type="p"
            typographyColor="#8E9D8F"
            typographyMargin="0 0 0.3rem 0"
            typographyAlign={width < 768 ? "center" : "left"}
          >
            Nasza Oferta
          </Typography>
        </a>
        <a href="#realizacje">
          <Typography
            type="p"
            typographyColor="#8E9D8F"
            typographyMargin="0 0 0.3rem 0"
            typographyAlign={width < 768 ? "center" : "left"}
          >
            Nasze realizacje
          </Typography>
        </a>
      </S.FastLinks>
    </S.Footer>
  );
}

export default Footer;
