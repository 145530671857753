import * as S from "./App.styles";
import Footer from "./Footer/Footer";
import Gallery from "./Gallery/Gallery";
import Hero from "./Hero/Hero";
import MainDescription from "./MainDescription/MainDescription";
import Offer from "./Offer/Offer";
import TilesSection from "./TilesSection/TilesSection";

function App() {
  return (
    <>
      <Hero />
      <MainDescription />
      <TilesSection />
      <Offer />
      <Gallery />
      <Footer />
    </>
  );
}

export default App;
