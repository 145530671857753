import styled from "styled-components";

export const Footer = styled.section`
  display: flex;
  flex-direction: row;
  background-color: #053d2a;
  gap: 10%;
  padding: 7rem;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 3rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const LogoFooter = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  width: 7rem;
`;

export const LogoImage = styled.img`
  border-radius: 100%;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FastLinks = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
