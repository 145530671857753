import { useEffect, useState } from "react";
import Typography from "../atoms/Typography";
import * as S from "./TilesSection.styles";
import { useInView } from "react-intersection-observer";

function TilesSection() {
  const { ref: firstPhoto, inView: isFistInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: secondPhoto, inView: isSecondInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: thirdPhoto, inView: isThirdInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  useEffect(() => {
    if (isSecondInView) {
      setTimeout(() => {
        setIsSecondAnimationDone(true);
      }, 900);
    }

    if (isFistInView) {
      setTimeout(() => {
        setIsFirstAnimationDone(true);
      }, 900);
    }
  }, [isSecondInView, isFistInView]);

  const [isFirstAnimationDone, setIsFirstAnimationDone] = useState(false);
  const [isSecondAnimationDone, setIsSecondAnimationDone] = useState(false);

  const [isFirstPhotoLoaded, setIsFirstPhotoLoaded] = useState(false);
  const [isSecondPhotoLoaded, setIsSecondPhotoLoaded] = useState(false);
  const [isThirdPhotoLoaded, setIsThirdPhotoLoaded] = useState(false);

  return (
    <S.TilesSection data-test-id="Tiles_Section" id="dlaczego-warto">
      <S.ImageContainer data-test-id="Image_Container">
        <S.Image
          alt="water tile"
          src={`${process.env.PUBLIC_URL}/${
            isFirstPhotoLoaded ? "tile_1" : "waterLow"
          }.webp`}
          loading="lazy"
          onLoad={() => setIsFirstPhotoLoaded(true)}
        />
        <S.ImageTile
          ref={firstPhoto}
          $isInView={isFistInView}
          $isLeftOne={true}
        >
          Automatyczny system pozwala na podlewanie w optymalnych godzinach,
          najlepiej nad ranem lub późnym wieczorem, co w dużym stopniu zwiększa
          efektywność procesu.
        </S.ImageTile>
      </S.ImageContainer>
      <S.ImageContainer>
        <S.Image
          alt="Tile Section Water"
          src={`${process.env.PUBLIC_URL}/${
            isSecondPhotoLoaded ? "water_2" : "water_2_Low"
          }.webp`}
          onLoad={() => setIsSecondPhotoLoaded(true)}
          loading="lazy"
        />
        <S.ImageTile
          ref={secondPhoto}
          $isInView={isSecondInView}
          $isLeftOne={false}
          onAnimationEnd={() => setIsFirstAnimationDone(true)}
        >
          Inwstując w automatyczne nawadnianie zyskujemy cenny czas, ciesząc się
          precyzyjnym podlewaniem, eliminujemy problem podczas naszej
          nieobecności zapewniając roślinom stały dostęp do wody. Ponadto system
          zintegrowany jest z czujnikami pogodowymi co pozwala na dostosowanie
          podlewania do warunków atmosferycznych.
        </S.ImageTile>
      </S.ImageContainer>
      <S.LongImageContainer data-test-id="Image_Container">
        <S.LongImage
          alt="Grass photo"
          src={`${process.env.PUBLIC_URL}/${
            isThirdPhotoLoaded ? "water" : "waterLow"
          }.webp`}
          loading="lazy"
          onLoad={() => setIsThirdPhotoLoaded(true)}
        />
        <S.LongImageTextContainer
          ref={thirdPhoto}
          $isInView={isThirdInView}
          $isLeftOne={false}
        >
          Montujemy instalacje, w nowo zakładanych ogrodach jak i już
          istniejących, ograniczając przy tym maksymalnie inwazję w teren-wykopy
          prowadzone za pomocą koparki łańcuchowej. (szerokość wykopu 10cm
          głębokość do 60cm) Zraszacze oraz linie kroplujące zaprojektowane tak,
          aby dostarczyć każdej roślinie w twoim ogrodzie odpowiednią dawkę
          wody. Pracujemy tylko na produktach renomowanych firm. Dziedzina
          nawodnień to nasza mocna strona, zdobyte doświadczenie sprawia, że
          robimy to naprawdę dobrze! Możliwość wyboru zasilania systemu: studnia
          wiercona, woda z sieci czy ostatnio bardzo popularne wykorzystanie
          wody deszczowej, budujemy też instalacje odwadniające włącznie z
          montażem zbiorników na deszczówkę. Dostosowujemy się do potrzeb
          klienta, oferując przy tym konkurencyjne ceny. Nieodpłatnie wykonujemy
          projekt oraz wycenę. W ofercie również kompleksowe zakładanie
          trawników wysiewanych lub z rolki. Zadzwoń, pomożemy, doradzimy! To
          nic nie kosztuje, Zachęcamy do współpracy!
        </S.LongImageTextContainer>
      </S.LongImageContainer>
    </S.TilesSection>
  );
}

export default TilesSection;
