import * as S from "./Hero.styles";
import Typography from "../atoms/Typography";
import { useState } from "react";

function Hero() {
  const [isHeroPhotoLoaded, setIsHeroPhotoLoaded] = useState(false);
  const width = window.innerWidth;

  return (
    <S.HeroSection data-test-id="Hero_Section" id="hero">
      <S.HeroMenu data-test-id="Hero_Menu">
        <S.LogoMenu data-test-id="Logo_Menu">
          <S.LogoImage
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="Aqua Shot Logo"
          />
        </S.LogoMenu>
        {width > 1150 ? (
          <S.InteractiveSection data-test-id="Interactive_Section">
            <S.Link href="#dlaczego-warto">
              <Typography type="h5" typographySize="1rem">
                Dlaczego warto
              </Typography>
            </S.Link>
            <S.Link href="#o-nas">
              <Typography type="h5" typographySize="1rem">
                Nasza oferta
              </Typography>
            </S.Link>
            <S.Link href="#realizacje">
              <Typography type="h5" typographySize="1rem">
                Nasze realizacje
              </Typography>
            </S.Link>
            <S.Link href="#kontakt">
              <Typography type="h5" typographySize="1rem">
                Kontakt
              </Typography>
            </S.Link>
          </S.InteractiveSection>
        ) : null}
      </S.HeroMenu>
      <S.MainInformationPanel data-test-id="Main_Information_Panel">
        <S.HeroTextColumn data-test-id="Hero_Text_Column">
          <Typography
            type="h1"
            typographyColor="#fff"
            typographyWeight="bold"
            typographySize="3rem"
          >
            Nawadniaj swoją zieloną przestrzeń!
          </Typography>
          <Typography
            type="p"
            typographyColor="#AEB2A9"
            typographySize={width > 1000 ? "1.5rem" : "1.1rem"}
            typographyLineHeight="2.5rem"
          >
            Projektujemy oraz instalujemy nowoczesne, automatyczne systemy
            nawadniające, które zapewniają odpowiednią dawkę wody dla roślin
            poprzez linie kroplujące oraz profesjonalne zraszacze trawnikowe.
            Zadbaj o to z naszą pomocą, skontaktuj się aby dowiedzieć się jak
            możemy Ci pomóc.
          </Typography>
          <S.HeroButton href="tel:721-491-336">
            <Typography
              type="span"
              typographyColor="#fff"
              typographyWeight="normal"
              typographySize="1.2rem"
            >
              Zadzwoń
            </Typography>
          </S.HeroButton>
        </S.HeroTextColumn>
        <S.HeroImageContainer data-test-id="Hero_Image">
          <S.HeroImage
            alt="Hero Image"
            src={`${process.env.PUBLIC_URL}/${
              isHeroPhotoLoaded ? "heroPhoto" : "heroPhotoLow"
            }.webp`}
            // loading="lazy"
            onLoad={() => setIsHeroPhotoLoaded(true)}
          />
        </S.HeroImageContainer>
      </S.MainInformationPanel>
    </S.HeroSection>
  );
}

export default Hero;
